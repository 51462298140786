<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <CInput v-model.trim="formData.email" size="sm" :label="$t('profile.email')" readonly />

        <div class="form-group">
          <label class="col-form-label-sm" for="white-list">{{ $t('profile.approved_sites') }}</label>
          <multiselect
            id="white-list"
            v-model="formData.approvedSites"
            :multiple="true"
            :placeholder="$t('profile.approved_sites')"
            :options="sitesList"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">{{ $t('ui.update') }}</CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_FULL_SITES_LIST } from '@/store/action-types/admin';

export default {
  name: 'AdminAdvertiserForm',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        approvedSites: [],
        balance: this.initialValues.balance,
        email: this.initialValues.email,
        id: this.initialValues.id,
      },
    };
  },
  computed: {
    ...mapState({
      sitesList: (state) => state.admin.allSites.list,
    }),
  },
  watch: {
    sitesList(sites) {
      const approvedSites = this.initialValues.approvedSites;
      const selectedSites = [];

      if (approvedSites.length) {
        sites.forEach((site) => {
          approvedSites.forEach((item) => {
            if (site.id === Number(item.id)) {
              selectedSites.push(site);
            }
          });
        });
      }

      if (selectedSites.length) {
        this.formData = { ...this.formData, approvedSites: selectedSites };
      }
    },
  },
  mounted() {
    this.GET_FULL_SITES_LIST();
  },
  methods: {
    ...mapActions('admin', [GET_FULL_SITES_LIST]),
    goBack() {
      this.$router.go(-1);
    },
    formSubmit() {
      this.$emit('formSubmit', this.formData);
    },
  },
};
</script>
