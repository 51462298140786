<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody v-if="isLoading">
            <MbnSpinner />
          </CCardBody>

          <CCardBody v-if="error">
            <MbnErrorMessage />
          </CCardBody>

          <AdvertiserForm
            v-if="userProfile"
            :is-sending="isSending"
            :initial-values="initialValues"
            @formSubmit="onSubmit"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_USER_PROFILE, EDIT_ADVERTISER_PROFILE } from '@/store/action-types/admin';
import AdvertiserForm from './AdvertiserForm';
import MbnSpinner from '@/components/Spinner';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'AdminAdvertiserEdit',
  components: {
    AdvertiserForm,
    MbnSpinner,
    MbnErrorMessage,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.admin.userProfile.info,
      isLoading: (state) => state.admin.userProfile.isLoading,
      isSending: (state) => state.admin.advertisers.isSending,
      error: (state) => state.admin.userProfile.error,
    }),
    advertiserId() {
      return this.$route.params.id;
    },
    initialValues() {
      return {
        approvedSites: this.userProfile.approvedSites,
        balance: this.userProfile.balance,
        email: this.userProfile.email,
        id: this.userProfile.id,
      };
    },
  },
  mounted() {
    this.GET_USER_PROFILE({ id: this.advertiserId });
  },
  methods: {
    ...mapActions('admin', [GET_USER_PROFILE, EDIT_ADVERTISER_PROFILE]),
    onSubmit(formData) {
      this.EDIT_ADVERTISER_PROFILE({ id: this.advertiserId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });

          this.$router.push({ name: 'admin-advertisers' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
